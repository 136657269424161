.p-sub-item {
  display: flex;
  flex-direction: column;
  padding: 20px 5px;

  & > button {
    width: 100px;
    height: 34px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  & > h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 24px;
    font-weight: 500;
    color: var(--ui-secondary);

    & > img {
      width: 45px;
      height: 45px;
      object-fit: fill;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
      border-radius: 5px;
    }
  }

  & > p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .sub-list {
    display: flex;
    flex-direction: column;
  }
}
