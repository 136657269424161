.p-collect-items {
  .collect-pre-step {
    width: 100%;
    display: flex;
    flex-direction: column;

    .command-details {
      width: 100%;
      height: max-content;
      margin-bottom: 40px;
      padding: 20px;
      border: 2px dashed hsla(0, 9%, 83%, 0.5);
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      & > h5 {
        font-size: 18px;
        font-weight: 600;
        color: var(--ui-secondary);
        margin-bottom: 14px;
      }

      .content {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        h6 {
          font-size: 16px;
          font-weight: 600;
          color: var(--ui-secondary);
        }
      }

      .sub-content {
        margin-top: -11px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .data {
        font-size: 14px;
        font-weight: 400;

        strong {
          font-weight: 500;
        }
      }
    }

    p {
      width: 100%;
      margin-bottom: 20px;
      font-size: 16px;
      text-align: center;
    }

    button {
      padding: 8px;
    }
  }
}
