.p-admin-articles {
  display: flex;
  flex-direction: column;

  & > .return-button {
    width: 100px;
    height: 34px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  & > .new-button {
    width: max-content;
    height: 34px;
    font-size: 14px;
    margin-bottom: 10px;
    gap: 4px;
    align-self: center;
    margin-bottom: 16px;
  }

  & .admin-subCat-title {
    display: flex;
    gap: 10px;
    color: var(--ui-secondary);
    margin-top: 1rem;
    margin-bottom: 2rem;

    &__image {
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
      border-radius: 7px;
      padding: 3px;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
      }
    }

    &__left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .cat-label {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5;
      }

      & > .cat-quantity {
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }

  .article-cell {
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
    width: 100%;

    & > .img {
      display: flex;
      width: 80px;
      height: 80px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    & > .label {
      display: flex;
      line-height: 1.5;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      flex: 1;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      white-space: normal;
    }
  }

  .actions {
    display: flex;
    gap: 4px;
  }
}
