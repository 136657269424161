.p-items-list {
  display: flex;
  flex-direction: column;

  & > button {
    width: 100px;
    height: 34px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  & > .subCat-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 24px;
    font-weight: 500;
    color: var(--ui-secondary);

    & > img {
      width: 45px;
      height: 45px;
      object-fit: fill;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
      border-radius: 5px;
    }
  }

  & > h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 500;
    color: var(--text-secondary);
    line-height: 1.5;
    margin-bottom: 24px;
    margin-top: 1rem;
  }

  & > p {
    font-size: 1rem;
    margin-bottom: 16px;
  }

  & .list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2%;
    row-gap: 10px;

    & .item-card {
      display: flex;
      flex-direction: column;
      width: 32%;
      max-width: 200px;
      cursor: pointer;

      &:hover {
        & > .item-image {
          box-shadow: 0px 0px 5px hsla(77, 72%, 44%, 0.7);
        }
        & > .item-name {
          color: var(--ui-primary);
        }
      }

      & > .item-image {
        width: 100%;
        height: 100px;
        padding: 3px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        transition: box-shadow 100ms ease-in-out;

        & > img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 8px;
        }
      }

      & > .item-name {
        display: flex;
        justify-content: center;
        padding-top: 5px;
        font-size: 1rem;
        font-weight: 500;
        transition: color 100ms ease-in-out;
        text-transform: capitalize;
        text-align: center;
      }
    }
  }
}
