.l-dashboard-header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;

  .content {
    position: relative;
    max-width: 768px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15),
      0 1px 2px 0 rgba(0, 0, 0, 0.3);

    .title {
      color: var(--text-secondary);
      font-size: 22px;
      line-height: 1.25;
      font-weight: 500;
    }

    .menu-button {
      position: absolute;
      left: 18px;
    }

    .cart-button {
      position: absolute;
      right: 18px;
    }
  }
}
