.p-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;

  .welcome-message {
    font-weight: 700;
    line-height: 1.25;
    display: flex;
    flex-direction: column;
    margin: 36px 0;

    .welcome {
      font-size: 24px;
    }
    .name {
      font-size: 36px;
      text-transform: capitalize;
      margin-bottom: 12px;
    }
    .logout-button {
      width: 160px;
      height: 40px;

      button {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .text-interim {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .options {
    display: flex;
    flex-direction: column;
    width: 100%;

    .l-dashboard-option {
      margin-bottom: 30px;
    }
  }
}
