.c-collect-modal {
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-bottom: 16px;
    align-self: center;
    font-size: 18px;
    font-weight: 600;
    color: var(--ui-secondary);

    em {
      color: var(--text-primary);
    }
  }

  .MuiStepLabel-root {
    svg {
      width: 30px;
      height: 30px;

      text {
        fill: white;
      }
    }

    .MuiStepLabel-label {
      font-size: 15px;
    }
  }

  .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
  }

  .article-img {
    width: fit-content;
    height: 60px;
    margin-bottom: 10px;
    object-fit: contain;
    border-radius: 8px;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  & > button {
    margin-top: 40px;
    padding: 8px;
  }
}
