.p-location-datetime {
  display: flex;
  flex-direction: column;

  .date-label {
    position: absolute;
    top: -7px;
    left: 8px;
    font-size: 10px;
    width: fit-content;
    height: fit-content;
    padding: 0 5px;
    background: white;
    color: rgba(0, 0, 0, 0.6);
    z-index: 10;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .date-group {
    display: flex;
    gap: 10px;
    margin-top: 5px;

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;

      & > label {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 2px;
      }
    }
  }

  .no-client {
    font-size: 12px;
    margin-bottom: 4px;
    line-height: 1.5;

    & > em {
      font-weight: 500;
      color: var(--ui-primary);
      cursor: pointer;
      transition: all ease-in-out 100ms;

      &:hover {
        font-weight: 700;
      }
    }
  }

  & > .header-icon {
    margin-top: -18px;
    align-self: center;
    color: var(--ui-secondary);
  }

  & > h3 {
    margin-bottom: 18px;
    text-align: center;
    font-size: 1.15rem;
    font-weight: 600;
  }

  & form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    align-self: center;
    width: 100%;

    & > .button-group {
      justify-content: center;
    }
    & .submit-button {
      height: 40px;
      width: 150px;
    }
  }
}
