@import "~rsuite/dist/rsuite.min.css";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

html {
  font-size: 14px;
  line-height: 1.2;

  // @media only screen and (max-width: 600px) {
  //   font-size: 14px;
  // }

  // @media only screen and (min-width: 1600px) {
  //   font-size: 14px;
  // }
}

body {
  min-height: 100vh;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("./assets/images/location-bg-motifs.png");
    opacity: 0.08;
    z-index: -1;
  }

  #root {
    min-height: 100vh;
    min-height: 100dvh;
  }
}

.no-element {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text-disabled);
}

.page {
  padding: 15px 20px;

  &__title {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    color: var(--text-secondary);
    text-transform: unset;

    .icon {
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--ui-secondary);
      border-radius: 7px;
      color: inherit;
    }
  }
}
