.p-admin-items {
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    color: var(--text-secondary);
    text-transform: uppercase;

    .icon {
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--ui-secondary);
      color: var(--text-secondary);
      border-radius: 7px;
    }
  }

  & > .return-button {
    width: 100px;
    height: 34px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  & > .new-button {
    width: max-content;
    height: 34px;
    font-size: 14px;
    margin-bottom: 10px;
    gap: 4px;
    align-self: center;
    margin-bottom: 16px;
  }

  & .admin-subCat-title {
    display: flex;
    gap: 10px;
    color: var(--ui-secondary);
    margin-top: 1rem;
    margin-bottom: 2rem;

    &__image {
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
      border-radius: 7px;
      padding: 3px;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
      }
    }

    &__left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .cat-label {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5;
      }

      & > .cat-quantity {
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }

  & h2.subtitle {
    font-size: 1.25rem;
    letter-spacing: 1px;
    font-weight: 500;
    color: var(--ui-secondary);
    line-height: 1.5;
    margin-bottom: 16px;
    align-self: center;
  }

  & > p {
    font-size: 1rem;
    margin-bottom: 16px;
  }

  & .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}
