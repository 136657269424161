.c-cart-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 10px;
  width: 400px;
  max-height: 90vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  .cart-title {
    font-size: 1.35rem;
    line-height: 1.5;
    margin-bottom: 10px;
    color: var(--ui-secondary);
    border-bottom: 2px solid var(--ui-grey-lighter);
    padding-bottom: 5px;
  }

  .cart-content {
    overflow: auto;
    flex: 1;
    padding: 0 8px;

    .item-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 0;
      width: 100%;

      &__delete {
        flex-shrink: 0;
        display: flex;
        align-items: center;
      }
      &__content {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        & > .name {
          font-weight: 500;
          font-size: 1rem;

          & > em {
            color: var(--ui-blue-normal);
            font-weight: 600;
            font-size: 1.1rem;
          }
        }

        & > .category {
          font-size: 0.8rem;
          color: var(--ui-grey-normal);
        }
      }
      &__price {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & > .price {
          font-size: 1.1rem;
          font-weight: 800;
          color: var(--ui-blue-normal);
          letter-spacing: 1px;
        }

        & > .calcul {
          font-size: 0.75rem;
          color: var(--ui-grey-normal);
        }
      }
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(192, 192, 192, 0.6);
      border: 3px solid rgba(255, 255, 255, 0.1);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      transition: background-color 500ms;

      &:hover {
        background-color: rgba(192, 192, 192, 0.9);
      }
    }
  }

  .cart-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 16px;

    & button {
      height: 25px;
      font-size: 0.8rem;
    }
  }

  hr {
    margin: 0;
  }
}
