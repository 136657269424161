.c-create-category {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 0;
  width: 320px;
  max-height: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 28px;

  @media only screen and (min-width: 500px) {
    width: 400px;
    max-height: 550px;
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0px 24px 10px;
  }
  .title {
    margin-bottom: 15px;
    margin-left: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.33;
  }
}
