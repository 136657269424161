.p-admin {
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 14px;
  }

  .admin-menus {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
