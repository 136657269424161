.logo-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: hsla(77, 72%, 44%, 0.102);
  border-radius: 5px;

  .left-logo {
    display: flex;
    align-items: center;
    width: 200px;
    img {
      width: 100%;
      object-fit: contain;
      transform: scale(0.75);
    }
  }

  .right-logo {
    display: flex;
    align-items: flex-end;
    width: 76px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
