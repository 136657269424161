.main-container {
  max-width: 500px;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;
  margin: auto;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: white;
    opacity: 0.8;
    z-index: -1;
  }
}
