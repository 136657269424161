.c-subitem-panel {
  .panel-header {
    & .MuiAccordionSummary-content {
      justify-content: space-between;
    }

    & h6 {
      min-width: 44%;
      max-width: 60%;
      flex-shrink: 0;
      padding-right: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      display: flex;
      align-items: center;
      color: var(--text-secondary);
    }

    .badges {
      max-width: 35%;
      margin-right: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;
      justify-content: right;
    }

    .badge-metric {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1px;
      min-width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 14px;
      border: 3px solid;
      padding: 0 4px;

      &.blue {
        border-color: dodgerblue;
      }
      &.green {
        border-color: limegreen;
      }
      &.primary {
        border-color: var(--ui-primary);
      }
    }
  }

  .panel-content {
    display: flex;
    align-items: center;

    .left-part {
      display: flex;

      img {
        width: 100px;
        object-fit: contain;
      }
    }
    .right-part {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .up-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        & > span {
          font-size: 13px;
          font-weight: 500;

          & > em {
            font-weight: 700;
            font-size: 14px;
            color: var(--ui-blue-normal);
          }
        }
        & > p {
          font-size: 13px;
        }
        & > div {
          flex: 2;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .submit-button {
        width: 142px;
        height: 28px;
        font-size: 14px;
      }
    }
    input[type="number"] {
      border: 1px solid #444;
      border-radius: 4px;
      font-size: 14px;
      width: 30px;
      padding-left: 5px;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
