.p-admin-users {
  display: flex;
  flex-direction: column;

  & > button {
    margin-bottom: 14px;
  }

  h2 {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    color: var(--text-secondary);
    text-transform: uppercase;

    .icon {
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--ui-secondary);
      border-radius: 7px;
      color: inherit;
    }
  }

  .new-user {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;

    & > button {
      width: 180px;
      height: 36px;
      font-size: 14px;
    }
  }

  .actions {
    display: flex;
    gap: 4px;
  }
}
